<template>
  <div class="card">
    <div class="card-body">
      <h3>Loop</h3>
      <div v-if="plan" class="livechat-plan">
        <h4>Price</h4>
        <h5 v-if="coupon">$ {{ plan.price - coupon.amount_off }} ( <i class="text-danger" style="text-decoration:line-through;">{{ plan.price }}</i> ) / Month</h5>
        <h5 v-else>${{ plan.price }} / Month</h5>
        <div class="row">
          <!-- eslint-disable vue/no-v-html -->
          <div class="col-md-12" v-html="plan.desc"></div>
          <!--eslint-enable-->
        </div>

        <div v-if="!user.agency.is_enabled_loop" class="row mt-4 mb-3">
          <div class="col-md-6">
            <div class="form-group d-flex">
              <input v-model="couponCode" type="text" class="form-control" placeholder="Discount Code" :disabled="coupon">
              <button v-if="!coupon" style="width:150px;" :disabled="!couponCode || isLoadingCoupon" class="btn btn-success ml-4" @click="checkCoupon" >
                Apply
              </button>
            </div>
            <strong v-if="coupon" class="text-primary">Coupon is valid</strong>
          </div>
        </div>

        <button v-if="!user.agency.is_enabled_loop" style="width:300px;" class="btn btn-primary" :disabled="isLoadingSubscription" @click="subscription">
          <b-spinner v-if="isLoadingSubscription" small />
          <span v-else>
            Subscribe
          </span>
        </button>
        <h3 v-else class="text-primary text-left mt-4">
          Your Subscription is Active now.
        </h3>
      </div>
      <div v-if="isLoadingPlan" class="text-center">
        <b-spinner />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoadingPlan: false,
      plan: null,
      couponCode: null,
      isLoadingSubscription: false,
      isLoadingCoupon: false,
      coupon: null,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    this.getLoopPlan()
  },

  methods: {
    getLoopPlan() {
      this.isLoadingPlan = true

      this.$store.dispatch('loop/getPlan')
        .then((plan) => {
          this.isLoadingPlan = false
          this.plan = plan
        })
        .catch(() => {
          this.isLoadingPlan = false
        })
    },

    subscription() {
      this.isLoadingSubscription = true

      this.$store.dispatch('loop/subscription', this.coupon ? this.couponCode : null)
        .then(() => {
          this.isLoadingSubscription = false
        })
        .catch(() => {
          this.isLoadingSubscription = false
        })
    },

    checkCoupon() {
      this.isLoadingCoupon = true

      this.$store.dispatch('loop/checkCoupon', this.couponCode)
        .then((coupon) => {
          this.coupon = coupon
          this.isLoadingCoupon = false
        })
        .catch(() => {
          this.isLoadingCoupon = false
        })
    }
  }
}
</script>